import React, { Suspense } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';

// components
import Root from './Root';
import PrivateRoute from './PrivateRoute';
import GlobalRoutes from './GlobalRoute';

// lazy load all the views
// auth
const Login = React.lazy(() => import('../pages/auth/Login'));
const SignUp = React.lazy(() => import('../pages/auth/SignUp'));
const ForgetPassword = React.lazy(() => import('../pages/auth/ForgetPassword'));
const Confirm = React.lazy(() => import('../pages/auth/Confirm'));
const Logout = React.lazy(() => import('../pages/auth/Logout'));

// home
const Dashboard = React.lazy(() => import('../pages/Dashboard'));

// Analytics
const ReviewInti = React.lazy(() => import('../pages/Analytics/Inti'));
const ReviewPPL = React.lazy(() => import('../pages/Analytics/PPL'));
const ReviewRHPP = React.lazy(() => import('../pages/Analytics/RHPP'));
const ReviewRHPPRating = React.lazy(() => import('../pages/Analytics/RHPP/Rating'));

// Laporan
const MonitoringSaldo = React.lazy(() => import('../pages/Laporan/MonitoringSaldo'));
const StokAyam = React.lazy(() => import('../pages/Laporan/StokAyam'));
const PenjualanGabungan = React.lazy(() => import('../pages/Laporan/PenjualanGabungan'));
const PenjualanRealisasi = React.lazy(() => import('../pages/Laporan/PenjualanRealisasi'));
const PenjualanRealisasiMarketing = React.lazy(() => import('../pages/Laporan/PenjualanRealisasiMarketing'));
const stokAyamRphu = React.lazy(() => import('../pages/Laporan/StokAyamRphu'));

const RekonsilPiutangBakul = React.lazy(() => import('../pages/Laporan/RekonsilPiutangBakul'));
const RekapitulasiPembayaranBakul = React.lazy(() => import('../pages/Laporan/RekapitulasiPembayaranBakul'));
const PinaltyBakulOverTerm = React.lazy(() => import('../pages/Laporan/PinaltyBakulOverTerm'));
const PrintKeterangan = React.lazy(() => import('../pages/Laporan/StokAyam/Print/print'));
const RekapHistoryPengajuanLimit = React.lazy(() => import('../pages/Laporan/RekapHistoryPengajuanLimit'));

const LaporanSharingAccessBakul = React.lazy(() => import('../pages/Laporan/SharingAccessBakul'));

const LaporanZonasiHargaPenjualan = React.lazy(() => import('../pages/Laporan/ZonasiHargaPenjualan'));
const LaporanEvaluasiHarga = React.lazy(() => import('../pages/Laporan/EvaluasiHarga'));
const LaporanDoDibawahHargaMinimum = React.lazy(() => import('../pages/Laporan/DoDibawahHargaMinimum'));

const LaporanPengajuanPanen = React.lazy(() => import('../pages/Laporan/PengajuanPanen'));

//produksi
const EstimasiRhpp = React.lazy(() => import('../pages/Produksi/EstimasiRHPP'));

//radar
const Radar = React.lazy(() => import('../pages/Radar'));
const SensorIoT = React.lazy(() => import('../pages/SensorIoT'));
const SensorIoTDetail = React.lazy(() => import('../pages/SensorIoT/detail'));
const DataPerJam = React.lazy(() => import('../pages/DataPerJam'));

// Universal Master
const KonsolidasiBakul = React.lazy(() => import('../pages/Master/KonsolidasiBakul'));
const KonsolidasiBakulDetail = React.lazy(() => import('../pages/Master/KonsolidasiBakul/Detail'));
const KonsolidasiBakulMerge = React.lazy(() => import('../pages/Master/KonsolidasiBakul/Merge'));
const BatasHariOperasional = React.lazy(() => import('../pages/Master/BatasHariOperasional'));
const PanduanPanen = React.lazy(() => import('../pages/Master/PanduanPanen'));
const TechnicalSupport = React.lazy(() => import('../pages/Master/TechnicalSupport'));
const TechnicalSupportEdit = React.lazy(() => import('../pages/Master/TechnicalSupport/Edit'));
const KepalaProduksi = React.lazy(() => import('../pages/Master/KepalaProduksi'));
const KepalaProduksiEdit = React.lazy(() => import('../pages/Master/KepalaProduksi/Edit'));
const MasterMenu = React.lazy(() => import('../pages/Master'));

// User Role
const Role = React.lazy(() => import('../pages/User/Role'));
const TambahRole = React.lazy(() => import('../pages/User/Role/Tambah'));
const EditRole = React.lazy(() => import('../pages/User/Role/Edit'));

const ResetPassword = React.lazy(() => import('../pages/User/ResetPassword'));

const PeranPengguna = React.lazy(() => import('../pages/User/PeranPengguna'));
const EditPengguna = React.lazy(() => import('../pages/User/PeranPengguna/Edit'));

// Map
const MappingFlok = React.lazy(() => import('../pages/Mapping/Flok'));
const MappingFlokTechnicalSupport = React.lazy(() => import('../pages/Mapping/Flok/TechnicalSupport'));

const ProfilePlasmaList = React.lazy(() => import('../pages/Profile/Plasma/List'));
const ProfilePlasmaDetail = React.lazy(() => import('../pages/Profile/Plasma/Detail'));
// const PlasmaProfileEdit = React.lazy(() => import('../pages/Profile/Plasma/Edit'));

const ProfileUnitDetail = React.lazy(() => import('../pages/Profile/Unit/Detail'));

const ProfileUnit = React.lazy(() => import('../pages/Profile/ListUnit'));

//Planning
const PpicTambah = React.lazy(() => import('../pages/Planning/PPIC/Tambah'));
const PpicList = React.lazy(() => import('../pages/Planning/PPIC/List'));
const PpicDetail = React.lazy(() => import('../pages/Planning/PPIC/Detail'));
const PpicEdit = React.lazy(() => import('../pages/Planning/PPIC/Edit'));

// Zonasi
const ZonasiWilayahPenjualanList = React.lazy(() => import('../pages/Master/Zonasi/WilayahPenjualan/List'));
const ZonasiWilayahPenjualanTambah = React.lazy(() => import('../pages/Master/Zonasi/WilayahPenjualan/Tambah'));
const ZonasiWilayahPenjualanEdit = React.lazy(() => import('../pages/Master/Zonasi/WilayahPenjualan/Edit'));

const ZonasiHargaPenjualan = React.lazy(() => import('../pages/Master/Zonasi/HargaPenjualan'));

const ZonasiPlasmaList = React.lazy(() => import('../pages/Master/Zonasi/Plasma/List'));
const ZonasiPlasmaTambah = React.lazy(() => import('../pages/Master/Zonasi/Plasma/Tambah'));
const ZonasiPlasmaEdit = React.lazy(() => import('../pages/Master/Zonasi/Plasma/Edit'));
const ZonasiPlasmaDetail = React.lazy(() => import('../pages/Master/Zonasi/Plasma/Detail'));

const ApprovalDoDibawahHargaMinimum = React.lazy(() => import('../pages/Approval/DoDibawahHargaMinium'));
const ApprovalSharingAccessBakul = React.lazy(() => import('../pages/Approval/SharingAccessBakul'));
const ApprovalPlafonBakul = React.lazy(() => import('../pages/Approval/PlafornBakul'));
const ApprovalMenu = React.lazy(() => import('../pages/Approval'));

const BakulOK = React.lazy(() => import('../pages/Master/BakulOK'));

const BakulExcludeList = React.lazy(() => import('../pages/Master/BakulExclude/list'));
const PlafonBakul = React.lazy(() => import('../pages/Master/PlafonBakul/list'));
const PlafonBakulSetting = React.lazy(() => import('../pages/Master/PlafonBakul/edit'));
const PlafonBakulDetail = React.lazy(() => import('../pages/Master/PlafonBakul/detail'));

const KetentuanDo = React.lazy(() => import('../pages/Master/KetentuanDo'));
const KetentuanDoEdit = React.lazy(() => import('../pages/Master/KetentuanDo/edit'));

const loading = () => (
    <div className="div-loading">
        <div className="loading-container">
            <div className="item"></div>
            <div className="item"></div>
            <div className="item"></div>
            <div className="item"></div>
        </div>
    </div>
);

type LoadComponentProps = {
    component: React.LazyExoticComponent<() => JSX.Element>;
};

const LoadComponent = ({ component: Component }: LoadComponentProps) => (
    <Suspense fallback={loading()}>
        <Component />
    </Suspense>
);

const AllRoutes = () => {
    return useRoutes([
        {
            // root route
            path: '/',
            element: <Root />,
        },
        {
            // public routes
            path: '/',
            element: <GlobalRoutes />,
            children: [
                {
                    path: 'auth',
                    children: [
                        { path: 'login', element: <LoadComponent component={Login} /> },
                        { path: 'signup', element: <LoadComponent component={SignUp} /> },
                        { path: 'forget-password', element: <LoadComponent component={ForgetPassword} /> },
                        { path: 'confirm', element: <LoadComponent component={Confirm} /> },
                        { path: 'logout', element: <LoadComponent component={Logout} /> },
                    ],
                },
            ],
        },
        {
            // protected routes
            path: '/',
            element: localStorage.getItem('shield_user') ? (
                <PrivateRoute status={true} />
            ) : (
                <Navigate to="/auth/login" />
            ),
            children: [
                { path: 'dashboard', element: <LoadComponent component={Dashboard} /> },
                {
                    path: 'Laporan',
                    children: [
                        { path: 'monitoring-saldo', element: <LoadComponent component={MonitoringSaldo} /> },
                        { path: 'stok-ayam', element: <LoadComponent component={StokAyam} /> },
                        { path: 'stok-ayam/print/:id', element: <LoadComponent component={PrintKeterangan} /> },
                        {
                            path: 'penjualan-gabungan',
                            element: <LoadComponent component={PenjualanGabungan} />,
                        },
                        {
                            path: 'penjualan-realisasi',
                            element: <LoadComponent component={PenjualanRealisasi} />,
                        },
                        {
                            path: 'penjualan-realisasi-marketing',
                            element: <LoadComponent component={PenjualanRealisasiMarketing} />,
                        },
                        {
                            path: 'stok-ayam-rphu',
                            element: <LoadComponent component={stokAyamRphu} />,
                        },
                        {
                            path: 'rekonsil-piutang-bakul',
                            element: <LoadComponent component={RekonsilPiutangBakul} />,
                        },
                        {
                            path: 'rekapitulasi-pembayaran-bakul',
                            element: <LoadComponent component={RekapitulasiPembayaranBakul} />,
                        },
                        {
                            path: 'pinalty-bakul-over-term',
                            element: <LoadComponent component={PinaltyBakulOverTerm} />,
                        },
                        {
                            path: 'rekap-history-pengajuan-limit',
                            element: <LoadComponent component={RekapHistoryPengajuanLimit} />,
                        },
                        {
                            path: 'sharing-access-bakul',
                            element: <LoadComponent component={LaporanSharingAccessBakul} />,
                        },
                        {
                            path: 'zonasi-harga-penjualan',
                            element: <LoadComponent component={LaporanZonasiHargaPenjualan} />,
                        },
                        {
                            path: 'evaluasi-harga',
                            element: <LoadComponent component={LaporanEvaluasiHarga} />,
                        },
                        {
                            path: 'do-dibawah-harga-minimum',
                            element: <LoadComponent component={LaporanDoDibawahHargaMinimum} />,
                        },
                        {
                            path: 'pengajuan-panen',
                            element: <LoadComponent component={LaporanPengajuanPanen}></LoadComponent>,
                        },
                    ],
                },
                {
                    path: 'produksi',
                    children: [{ path: 'estimasi-rhpp', element: <LoadComponent component={EstimasiRhpp} /> }],
                },
                {
                    path: 'analytics',
                    children: [
                        { path: 'inti', element: <LoadComponent component={ReviewInti} /> },
                        { path: 'ppl', element: <LoadComponent component={ReviewPPL} /> },
                        { path: 'rhpp', element: <LoadComponent component={ReviewRHPP} /> },
                        { path: 'rhpp/rating', element: <LoadComponent component={ReviewRHPPRating}></LoadComponent> },
                    ],
                },
                { path: 'atur-pengguna', element: <LoadComponent component={Radar} /> },
                { path: 'realtime-data', element: <LoadComponent component={SensorIoT} /> },
                { path: 'realtime-data/detail/:id', element: <LoadComponent component={SensorIoTDetail} /> },
                { path: 'realtime-data/data-per-jam', element: <LoadComponent component={DataPerJam} /> },
                {
                    path: 'master',
                    children: [
                        { path: 'menu', element: <LoadComponent component={MasterMenu} /> },
                        { path: 'batas-hari-operasional', element: <LoadComponent component={BatasHariOperasional} /> },
                        { path: 'panduan-panen', element: <LoadComponent component={PanduanPanen} /> },
                        {
                            path: 'bakul',
                            children: [
                                { path: 'konsolidasi', element: <LoadComponent component={KonsolidasiBakul} /> },
                                {
                                    path: 'konsolidasi/detail/:id',
                                    element: <LoadComponent component={KonsolidasiBakulDetail} />,
                                },
                                {
                                    path: 'penggabungan',
                                    element: <LoadComponent component={KonsolidasiBakulMerge} />,
                                },
                                {
                                    path: 'ketentuan-ok',
                                    element: <LoadComponent component={BakulOK}></LoadComponent>,
                                },
                                {
                                    path: 'exclude',
                                    element: <LoadComponent component={BakulExcludeList}></LoadComponent>,
                                },
                                {
                                    path: 'plafon',
                                    element: <LoadComponent component={PlafonBakul}></LoadComponent>,
                                },
                                {
                                    path: 'plafon/setting/:nik',
                                    element: <LoadComponent component={PlafonBakulSetting}></LoadComponent>,
                                },
                                {
                                    path: 'plafon/detail/:nik',
                                    element: <LoadComponent component={PlafonBakulDetail}></LoadComponent>,
                                },
                            ],
                        },
                        {
                            path: 'technical-support',
                            element: <LoadComponent component={TechnicalSupport}></LoadComponent>,
                        },
                        {
                            path: 'technical-support/edit/:perusahaanId/:pplId',
                            element: <LoadComponent component={TechnicalSupportEdit}></LoadComponent>,
                        },
                        {
                            path: 'kepala-produksi',
                            element: <LoadComponent component={KepalaProduksi}></LoadComponent>,
                        },
                        {
                            path: 'kepala-produksi/edit/:perusahaanId/:koordinatorId',
                            element: <LoadComponent component={KepalaProduksiEdit}></LoadComponent>,
                        },
                        {
                            path: 'zonasi',
                            children: [
                                {
                                    path: 'wilayah-penjualan',
                                    element: <LoadComponent component={ZonasiWilayahPenjualanList}></LoadComponent>,
                                },
                                {
                                    path: 'wilayah-penjualan/tambah',
                                    element: <LoadComponent component={ZonasiWilayahPenjualanTambah}></LoadComponent>,
                                },
                                {
                                    path: 'wilayah-penjualan/edit/:zonasiId',
                                    element: <LoadComponent component={ZonasiWilayahPenjualanEdit}></LoadComponent>,
                                },
                                {
                                    path: 'harga-penjualan',
                                    element: <LoadComponent component={ZonasiHargaPenjualan}></LoadComponent>,
                                },

                                {
                                    path: 'plasma',
                                    element: <LoadComponent component={ZonasiPlasmaList}></LoadComponent>,
                                },
                                {
                                    path: 'plasma/tambah',
                                    element: <LoadComponent component={ZonasiPlasmaTambah}></LoadComponent>,
                                },
                                {
                                    path: 'plasma/edit/:masterUnitId',
                                    element: <LoadComponent component={ZonasiPlasmaEdit}></LoadComponent>,
                                },
                                {
                                    path: 'plasma/detail/:masterUnitId',
                                    element: <LoadComponent component={ZonasiPlasmaDetail}></LoadComponent>,
                                },
                            ],
                        },
                        {
                            path: 'ketentuan-do',
                            element: <LoadComponent component={KetentuanDo}></LoadComponent>,
                        },
                        {
                            path: 'ketentuan-do/edit',
                            element: <LoadComponent component={KetentuanDoEdit}></LoadComponent>,
                        },
                    ],
                },
                {
                    path: 'user',
                    children: [
                        { path: 'role', element: <LoadComponent component={Role} /> },
                        { path: 'role/tambah', element: <LoadComponent component={TambahRole} /> },
                        { path: 'role/edit/:id', element: <LoadComponent component={EditRole} /> },
                        {
                            path: 'ubah-password',
                            element: <LoadComponent component={ResetPassword}></LoadComponent>,
                        },
                        { path: 'roles', element: <LoadComponent component={PeranPengguna} /> },
                        { path: 'role/edit-pengguna/:hashId', element: <LoadComponent component={EditPengguna} /> },
                    ],
                },
                {
                    path: 'mapping',
                    children: [
                        {
                            path: 'flok',
                            element: <LoadComponent component={MappingFlok} />,
                        },
                        {
                            path: 'flok/technical-support/',
                            element: <LoadComponent component={MappingFlokTechnicalSupport} />,
                        },
                    ],
                },
                {
                    path: 'profile',
                    children: [
                        {
                            path: 'plasma',
                            element: <LoadComponent component={ProfilePlasmaList}></LoadComponent>,
                        },
                        {
                            path: 'plasma/detail/:perusahaanId/:peternakId',
                            element: <LoadComponent component={ProfilePlasmaDetail}></LoadComponent>,
                        },
                        // {
                        //     path: 'profile/edit',
                        //     element: <LoadComponent component={PlasmaProfileEdit}></LoadComponent>,
                        // },
                        {
                            path: 'unit/detail/:perusahaanId/:cabangId',
                            element: <LoadComponent component={ProfileUnitDetail}></LoadComponent>,
                        },
                        {
                            path: 'unit',
                            element: <LoadComponent component={ProfileUnit}></LoadComponent>,
                        },
                    ],
                },
                {
                    path: 'planning',
                    children: [
                        {
                            path: 'ppic',
                            element: <LoadComponent component={PpicList}></LoadComponent>,
                        },
                        {
                            path: 'ppic/tambah',
                            element: <LoadComponent component={PpicTambah}></LoadComponent>,
                        },
                        {
                            path: 'ppic/edit/:hashId',
                            element: <LoadComponent component={PpicEdit}></LoadComponent>,
                        },
                        {
                            path: 'ppic/detail/:hashId',
                            element: <LoadComponent component={PpicDetail}></LoadComponent>,
                        },
                    ],
                },
                {
                    path: 'approval',
                    children: [
                        {
                            path: 'do-dibawah-harga-minimum',
                            element: <LoadComponent component={ApprovalDoDibawahHargaMinimum} />,
                        },
                        {
                            path: 'sharing-access-bakul',
                            element: <LoadComponent component={ApprovalSharingAccessBakul} />,
                        },
                        {
                            path: 'plafon-bakul',
                            element: <LoadComponent component={ApprovalPlafonBakul} />,
                        },
                        {
                            path: 'menu',
                            element: <LoadComponent component={ApprovalMenu} />,
                        },
                    ],
                },
            ],
        },
    ]);
};

export default AllRoutes;
